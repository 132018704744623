import React from "react";
import styled from "styled-components";
import PageHeading from "../helpers/page-heading";
import Link from "gatsby-link";
import PracticeAreas from "../helpers/practice-areas.json"
//import blankPic from "../img/blankpic.jpg"
import Banners from "../components/banners"
import Layout from "../layouts/index";

const Container = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: left;
    color: #6a6a6a;
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const ContentInner = styled.div`
    display: grid;
    justify-content: space-between;
    grid-gap: 3px;
    grid-template-columns: 1fr;
    padding-left: 90px;
    padding-right: 90px;
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 6000px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media only screen 
    and (max-width: 650px)  {
        grid-template-columns: 1fr;
    } 
    .button{
        text-align: center;
    }

    a {
        color:#4d4d4d;
        margin: 0px;
    }

    h3{
        text-transform: uppercase;
        margin: 0px;
        margin-bottom: 9px;
    }

    p{
        margin: 0px;
    }

    .attorney-item{
        img {
            margin: 0px;
            padding: 0px;
        }
    }

    .heading {
        margin-top: 30px;
    }

    .service-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
    }

    .service-item{
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 4px;
        background: #646464;
        border-bottom: solid #fce302 7px;
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: 5px 10px 10px rgba(0, 0, 0, .30);
        :hover{
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .30) inset;
        }
        .hidden-headline{
            display: none;
        }
        :hover{
            cursor: pointer;
        }
        a {
            color: #fff;
            font-size: 1.3em;
        }
    }

    .service-item-content {
        color: #413525;
        padding: 10px;
        background: #fce400;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 25px;
        box-shadow: 5px 10px 10px rgba(0, 0, 0, .20);
        :hover{
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .20) inset;
        }
        .button{
            :hover{
                cursor: pointer;
            }
            background: #515151;
            border-radius: 10px;
            text-transform: uppercase;
            color: #fff;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-top: 30px;
            margin-left: 150px;
            margin-right: 150px;
            @media only screen 
            and (min-width: 1px) 
            and (max-width: 6000px) {
                margin-left: 50px;
                margin-right: 50px;
            }
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .20);
            :hover{
                box-shadow: 5px 10px 10px rgba(0, 0, 0, .20) inset;
            }
        }
    }

    hr{
        margin: 0px;
    }

    .active{
        background: red;
    }

`;  

class Component extends React.Component {

    constructor(props) {
        super();
        this.state = {
        };
        this.render = this.render.bind(this);
        this.showDetail = this.showDetail.bind(this);
    }

    showDetail(e){
        console.log(e.target);
        //document.getElementById("succes").classList.add("hidden");
    }
  
    render() {
        //let self = this
        let practiceArea = {};
        console.log("props");
        console.log(this.props);
        if(this.props){
            if(this.props.pageContext){
                practiceArea = this.props.pageContext
            }
        }
    
        return (
            <Layout>
            <Container>
                <PageHeading>
                    <span className="heading-top">{practiceArea.name}</span><br/>
                    <br/>
                    <hr className="heading-line"/><br/>
                    <span className="heading-main">LOGGENBERG LITIGATION ATTORNEYS</span>
                </PageHeading>
                <ContentInner>
                    <div id={practiceArea.name} className="attorney-item">
                        
                        <div className="service-item-content">
                            <Banners layoutData={this.props}/>
                            <h3 className="heading">{practiceArea.name}</h3>
                            <br/> 
                            <div dangerouslySetInnerHTML={{ __html: practiceArea.detail01 }} />
                            <div>
                                <Link to="/contact"> <div className="button" >Contact Us</div></Link>
                            </div>
                        </div>

                        <div className="service-list">
                            {
                                PracticeAreas.map(function(area, i){
                                    return <div id={area.name}>
                                        <div>
                                            <div>
                                                <Link to={area.path}> <div className="service-item" activeClassName="active">{area.name}</div></Link>
                                            </div>
                                        </div> 
                                    </div>
                                })
                            }   
                        </div>
                    </div>
                </ContentInner>
            </Container>
            </Layout>
        ) 
    }
}

export default Component;

export const query = graphql`
query AttorneyQuery {
    site {
    siteMetadata {
        title
    }
    }
    allFile(filter: {relativeDirectory: {regex: "/img/banners/"}}) {
        edges {
          node {
            name
            relativePath
            prettySize
            extension
            birthTime
            childImageSharp {
              sizes(maxWidth: 1400) {
                  ...GatsbyImageSharpSizes_tracedSVG
                }
            }
          }
        }
      }
}
`