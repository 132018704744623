import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const BannerSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 9fr 1fr 1fr;
  grid-template-rows: 1fr;
  z-index: -100;
  img {
    transition: all 1s ease;
  }
  .shiftarrow{
    display: none;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, .30);
    :hover{
      background: #FFF20A;
      border: 1px solid #fff;
      color: #fff;
      box-shadow: 5px 10px 10px rgba(0, 0, 0, .30) inset;
      cursor: pointer;
    }
  }
  #leftarrow {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #bannerimages{
    grid-row: 1 / span 1;
    grid-column: 1 / span 6;
  }

  #rightarrow{
    grid-row: 1 / span 1;
    grid-column: 6 / span 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

class Component extends React.Component {

    constructor(state) {
      super();
      //console.log("State BEFORE:");
      //console.log(this.state);
      let componentState = {
        bannerIndex: 1
      }
      if(state){
        if(state.layoutData){
          if(state.layoutData.data){
            componentState.data = state.layoutData.data
            if(state.layoutData.data.allFile){
              if(state.layoutData.data.allFile.edges){
                componentState.bannerImages = state.layoutData.data.allFile.edges
              } 
            } 
          } 
          if(state.layoutData.location){
            if(state.layoutData.location.pathname){
              componentState.path = state.layoutData.location.pathname
            } 
          } 
        } 
      }
      this.state = componentState;
      //console.log("State AFTER:");
      //console.log(this.state);
      this.changeBanner = this.changeBanner.bind(this);      
    }

    componentDidMount(){
      let self = this

      setInterval(function(){ 
        let currentIndex = self.state.bannerIndex
        let arrayLength = self.state.bannerImages.length
        if(currentIndex===arrayLength-1){
          self.setState({
            bannerIndex: 0
          })
        }
        else{
          self.setState({
            bannerIndex: currentIndex +1
          })
        }
      }, 3000);
    }
  
    changeBanner(){
      //let newBannerState = bannerState + 1;
      this.setState({
        bannerState: 4
      })
      document.getElementById("page-header").classList.add("scrolled");
      setTimeout(function(){ 
        alert("HI");
      }, 2000);
    }
  
    render() {
      //let self = this;
  
      //let bannerState = this.state.bannerState;
  
      //let val = this.props.valueOf().location.pathname.toString();
      //console.log(val);


      return (
            <BannerSection onClick={this.handleScroll}>
              <div id="leftarrow">
                <span className="shiftarrow">&lt;</span>
              </div>
              <div id="bannerimages">
                <Img sizes={this.state.bannerImages[this.state.bannerIndex].node.childImageSharp.sizes}/>
              </div>
              <div id="rightarrow">
                <span className="shiftarrow">&gt;</span>
              </div>
              
            </BannerSection>
      ) 
    }
  }
  
  /*
  {this.state.bannerImages.map((image, index) => (
                  <Banner>
                      <Img key={index} sizes={image.node.childImageSharp.sizes} />
                  </Banner>
  ))}
  */
  
  export default Component;